import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  createTaxRateApi,
  createFormApi,
  getFormApi,
  updateFormApi,
  fetchFormApi,
  deleteFormApi,
  updateFormsSortApi,
  cloneFormIntoCategoryApi,
  getCagegoriesOfFormsApi,
} from "../../../api";
import {
  START_LOADING,
  SET_SUCCESS,
  SET_FAILELD,
  STOP_LOADING,
} from "../../../models";
import { AppState } from "../../reducers";
import { startLoading, stopLoading } from "../common/loading";

export const createFormAction = (
  body: any
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await createFormApi(body).xhr;
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: { success: true, message: "Form created successfully" },
        });
        return response;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const getFormAction = (
  id: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getFormApi(id).xhr;
      if (response) {
        return response;
      } else {
        throw "Not found";
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const updateFormAction = (
  id: number,
  body: any
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await updateFormApi(id, body).xhr;
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: { success: true, message: "Form updated successfully" },
        });
        return response;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const fetchFromsAction = (
  category_id: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await fetchFormApi(category_id).xhr;
      if (response) {
        dispatch({
          type: "SET_FORMS",
          payload: response,
        });
        return response;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const deleteFormAction = (
  id: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await deleteFormApi(id).xhr;
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: { success: true, message: "Form deleted" },
        });
        return response;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};



export const updateFormsSortAction = (
 formBody:any
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await updateFormsSortApi(formBody).xhr;
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: { success: true, message: "List updated successfully" },
        });
        return response;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const cloneFormIntoCategoryAction = (
  formBody:any
 ): ThunkAction<void, AppState, null, Action<string>> => {
   return async (dispatch: any, getState: any) => {
       const response = await cloneFormIntoCategoryApi(formBody).xhr;
       if (response) {
         return response;
       }
       return null
   };
 };
 

 export const getCagegoriesOfFormsAction = (
  formBody:any
 ): ThunkAction<void, AppState, null, Action<string>> => {
   return async (dispatch: any, getState: any) => {
       const response = await getCagegoriesOfFormsApi(formBody).xhr;
       if (response) {
         return response;
       }
       return null
   };
 };
 